/***** Font Definition for Open Sans. This stylesheet comes from qrohlf.com/posts/better-opensans *****/

/* Regular */
@font-face {
    font-family: 'Open Sans';
    
    src: url('../fonts/OpenSans-Regular-webfont.eot');
    src: url('../fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Regular-webfont.svg#OpenSansRegular') format('svg');
    font-weight: normal;
    font-weight: 400;
    font-style: normal;

}

/* Italic */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Italic-webfont.eot');
    src: url('../fonts/OpenSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Italic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Italic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Italic-webfont.svg#OpenSansItalic') format('svg');
    font-weight: normal;
    font-weight: 400;
    font-style: italic;

}

/* Light */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Light-webfont.eot');
    src: url('../fonts/OpenSans-Light-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Light-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Light-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Light-webfont.svg#OpenSansLight') format('svg');
    font-weight: 200;
    font-style: normal;

}

/* Light Italic */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-LightItalic-webfont.eot');
    src: url('../fonts/OpenSans-LightItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-LightItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-LightItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-LightItalic-webfont.svg#OpenSansLightItalic') format('svg');
    font-weight: 200;
    font-style: italic;

}

/* Semibold */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Semibold-webfont.eot');
    src: url('../fonts/OpenSans-Semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Semibold-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Semibold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Semibold-webfont.svg#OpenSansSemibold') format('svg');
    font-weight: 500;
    font-style: normal;

}

/* Semibold Italic */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-SemiboldItalic-webfont.eot');
    src: url('../fonts/OpenSans-SemiboldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-SemiboldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-SemiboldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-SemiboldItalic-webfont.svg#OpenSansSemiboldItalic') format('svg');
    font-weight: 500;
    font-style: italic;

}

/* Bold */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-Bold-webfont.eot');
    src: url('../fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('../fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-Bold-webfont.svg#OpenSansBold') format('svg');
    font-weight: bold;
    font-weight: 700;
    font-style: normal;

}

/* Bold Italic */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot');
    src: url('../fonts/OpenSans-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-BoldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-BoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-BoldItalic-webfont.svg#OpenSansBoldItalic') format('svg');
    font-weight: bold;
    font-weight: 700;
    font-style: italic;

}

/* Extra Bold */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBold-webfont.eot');
    src: url('../fonts/OpenSans-ExtraBold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-ExtraBold-webfont.woff') format('woff'),
         url('../fonts/OpenSans-ExtraBold-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-ExtraBold-webfont.svg#OpenSansExtrabold') format('svg');
    font-weight: 900;
    font-style: normal;

}

/* Extra Bold Italic */
@font-face {
    font-family: 'Open Sans';
    src: url('../fonts/OpenSans-ExtraBoldItalic-webfont.eot');
    src: url('../fonts/OpenSans-ExtraBoldItalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/OpenSans-ExtraBoldItalic-webfont.woff') format('woff'),
         url('../fonts/OpenSans-ExtraBoldItalic-webfont.ttf') format('truetype'),
         url('../fonts/OpenSans-ExtraBoldItalic-webfont.svg#OpenSansExtraboldItalic') format('svg');
    font-weight: 900;
    font-style: italic;

}

.checkbox-inline .theme-accent {
    display: inline-block !important;
}

.form_element.checkbox-inline {
    display: inline-block;
    clear: both;
    width: 100%;
}
