@import "colors";

/*======================================================================
  Selectric
======================================================================*/
.selectricWrapper {
  position: relative;
  margin: 0 0 10px;
  width: 230px;
  cursor: pointer;
}

.selectricResponsive {
  width: 100%;
}

.selectric {
  border: 1px solid #DDD;
  background: $grey-canon;
  position: relative;
  border-radius: 2px;

  .label {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	margin: 0 30px 0 0;
	padding: 6px;
	font-size: 12px;
	line-height: 18px;
	color: #444;
	min-height: 18px;
  }
  .button {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	width: 30px;
	height: 30px;
	color: #BBB;
	text-align: center;
	font: 0/0 a;
	/* IE Fix */
	*font: 20px/30px Lucida Sans Unicode, Arial Unicode MS, Arial;
  	&:after {
		content: " ";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		width: 0;
		height: 0;
		border: 4px solid transparent;
		border-top-color: #BBB;
		border-bottom: none;
  	}
  }
}

.selectricHover .selectric {
  	border-color: #CCC;
	.button {
		color: #888;
		&:after {
  			border-top-color: #888;
  		}
  	}
}

.selectricOpen {
  z-index: 9999;
  	.selectric {
		border-color: #CCC;
		background: #F0F0F0;
  	}
  	.selectricItems {
  	  	display: block;
  	}
}

.selectricDisabled {
  filter: alpha(opacity=50);
  opacity: 0.5;
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.selectricHideSelect {
  position: relative;
  overflow: hidden;
  width: 0;
  height: 0;
  select {
    position: absolute;
    left: -100%;
    display: none;
  }
}

.selectricInput {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  margin: 0 !important;
  padding: 0 !important;
  width: 1px !important;
  height: 1px !important;
  outline: none !important;
  border: none !important;
  *font: 0/0 a !important;
  background: none !important;
}

.selectricTempShow {
  position: absolute !important;
  visibility: hidden !important;
  display: block !important;
}

/* Items box */
.selectricItems {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #F9F9F9;
  border: 1px solid #CCC;
  z-index: -1;
  box-shadow: 0 0 10px -6px;
  .selectricScroll {
    height: 100%;
    overflow: auto;
  }

}
.selectricAbove .selectricItems {
  top: auto;
  bottom: 100%;
}
.selectricItems ul, .selectricItems li {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  min-height: 20px;
}
.selectricItems li {
  display: block;
  padding: 5px;
  border-top: 1px solid #FFF;
  border-bottom: 1px solid #EEE;
  color: #666;
  cursor: pointer;
  	&.selected {
  		background: #EFEFEF;
  		color: #444;
  		border-top-color: #E0E0E0;
	}
	&:hover {
		background: #F0F0F0;
		color: #444;
	}
	&.disabled {
		background: #F5F5F5;
		color: #BBB;
		border-top-color: #FAFAFA;
		cursor: default;
	}
}

/** custom styles */
