@mixin box-sizing($box-model) {
  -webkit-box-sizing: $box-model;
     -moz-box-sizing: $box-model;
          box-sizing: $box-model;
}

@mixin border-radius($radius) {
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
	border-radius: $radius;
}

@mixin clearfix() {
    &:before,
    &:after {
        content: "";
        display: table;
    }
    &:after {
        clear: both;
    }
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -ms-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin inline-block() {
  display: -webkit-inline-block;
  display: inline-block;
}
