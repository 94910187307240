.lang_de-de {

	nav.main ul li {
		
	}
	.device_stat dt span {
		font-size: 10px;
	}
	table tr th {
		font-size: 13px;
	}
	.content.profile .profile-field {
		width: 600px;
		label {
			width: 240px;
		}
	}

}