@import "reset";
@import "mixins";
@import "colors";
@import "helpers";
@import "jstree";
@import "selectric";
@import "lineart-font";
@import "bootstrap-icons";
@import "manage";
@import "notifications";
@import "popups";
@import "contextMenu";
@include global-reset;
@import "fonts";
$WIDTH: 1100px;
body {
    overflow-y: scroll;
    background: #efefef;
}

*,
html,
body {
    font-family: 'Open Sans';
    font-weight: 400;
}

*:focus {
    outline: 0;
}

#page {
    width: $WIDTH;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}

h1 {}

h2 {
    font-size: 28px;
    font-weight: 600px;
    margin: 4px 0 20px 0;
}

h3 {
    font-size: 16px;
    color: #b6b6b6;
    letter-spacing: 2px;
    margin: 0 0 10px 0;
    text-transform: uppercase;
    &.sub {
        margin: 20px 0 0 0;
        span {
            color: #666;
            &.head_exchanges {
                float: right;
                font-size: 13px;
                letter-spacing: 0px;
                color: #CFCFCF;
            }
        }
    }
    &.line {
        border-bottom: 1px solid #dadada;
        margin-bottom: 10px;
        padding-bottom: 3px;
    }
    &.btmline {
        border-bottom: 1px solid #dadada;
        margin-bottom: 10px;
        padding-bottom: 3px;
    }
    &.warrenty {
        margin-bottom: 10px;
    }
}

h4 {
    font-size: 18px;
    margin: 10px 0 0px;
    background: darken($grey-input, 5%);
    color: lighten($grey-dark, 5%);
    padding: 10px;
}

p {
    font-size: 14px;
    margin: 12px 0;
    color: lighten($grey-dark, 10%);
}

a {
    text-decoration: none;
}

sup {
    font-size: 0.58em;
    line-height: 0;
    vertical-align: super;
}

header.pre-header{
    background: transparent;
    width: auto;
    height: auto;
    float:right;
    padding: 5px 0 10px 0;
}

header {
    background: #fff;
    width: $WIDTH;
    height: 80px;
    margin: 0;
    border-top: 5px solid #efefef;
    position: relative;
    overflow: hidden;
    h1 {
        width: 200px;
        height: 55px;
        position: absolute;
        top: 6px;
        left: 12px;
        border-right: 3px solid #F3F3F3;
        padding-right: 9px;
        padding-top: 11px;
        font-size: 26px;
        font-weight: bold;
        color: #cc252f;
        a {
            font-size: 26px;
            font-weight: bold;
            color: #cc252f;
        }
        a span {
            display: block;
            font-weight: 600;
            color: #3c3c3c;
            font-size: 15px;
            text-transform: uppercase;
        }
        a em {
            font-weight: 300;
            color: lighten($grey-dark, 40%);
        }
        /* a {
			display: block;
			width: 152px;
			height: 40px;
			background: url(../images/logo.png) no-repeat;
			text-indent: -999999px;
			overflow: hidden;
		} */
    }
}

footer {
    background: #fff;
    border-top: #ce2229 10px solid;
    border-bottom: 20px solid #efefef;
    width: $WIDTH;
    height: 60px;
    margin: 0 0 0 0;
    position: relative;
    clear: both;
    .languages {
        width: 900px;
        margin: 0 0 0 10px;
        position: absolute;
        top: 16px;
        left: 0;
        li {
            @include inline-block();
            margin: 0 5px 0 0;
            font-size: 13px;
            &.select {
                display: block;
                font-size: 12px;
                color: $grey-dark;
                margin: 0 0 4px 0;
            }
            a {
                color: darken($grey-canon, 25%);
                &.active {
                    color: $red-canon;
                }
            }
        }
    }
}

nav {
    &.main {
        position: absolute;
        top: 6px;
        left: 238px;
        ul {
            overflow: hidden;
            li {
                float: left;
                width: 150px;
                height: 66px;
                font-weight: 400;
                display: block;
                margin: 0;
                text-align: center;
                line-height: 16px;
                background: #f3f3f3;
                border: none;
                border-right: 1px solid #e8e7e7;
                &.active {
                    a {
                        font-weight: bold;
                    }
                }
                &.active,
                &:hover {
                    background: #e4e4e4;
                    a {
                        color: #5f5f5f;
                    }
                }
                a {
                    color: #5f5f5f;
                    font-size: 12px;
                    font-weight: 600;
                    text-transform: uppercase;
                    padding-inline: 10px;
                    word-wrap: break-word;
                    height: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    /*&.notifreports {
                        margin-top: 16px;
                        line-height: 18px;
                    }*/
                }
                &:hover {
                    background: darken(#F3F3F3, 3%);
                    border-color: #E8E7E7;
                    a {
                        color: #5F5F5F;
                    }
                }
                &:last-child {
                    border: none;
                }
            }
        }
    }
    &.user {
        ul {
            padding-top: 0;
            li {
                @include inline-block();
                color: darken(#5f5f5f, 10%);
                font-size: 13px;
                font-weight: 300;
                a {
                    font-size: 13px;
                    font-weight: 300;
                    color: #5f5f5f;
                    i{
                        line-height: 20px;
                        vertical-align: text-top;
                        font-size: 15px;
                    }
                    &:hover {
                        color: darken(#5f5f5f, 50%);
                    }
                }
            }
        }
    }
}

#company-details {
    dl {
        @include inline-block();
        font-weight: 300;
        margin: 0 10px 0 0;
        background: url(../images/1x1-grey.png) repeat-x 0 18px;
        padding: 0 16px;
        text-align: center;
    }
    dt {
        width: 100%;
        color: darken($grey-input, 30%);
        font-size: 12px;
        padding: 4px 0 0;
        letter-spacing: 1px;
    }
    dd {
        width: 100%;
        font-size: 15px;
        color: $red-canon;
        font-weight: bold;
        margin: 0 0 4px;
        padding: 0 0;
    }
}

section {
    overflow: hidden;
    &.main {
        background: #fff;
        overflow: visible;
        *zoom: 1;
        &:after {
            content: "";
            display: table;
            clear: both;
        }
    }
}

aside {
    width: 248px;
    &.sidebar {
        float: left;
        background: $grey-input;
        width: 308px;
        margin-top: -33px;
        //@include border-radius(8px);
        //margin: 10px 0 0 10px;
        h4 {
            font-size: 13px;
            background: transparent;
            padding: 0 0 0 10px;
            font-weight: bold;
            cursor: pointer;
            &:hover {
                color: $red-canon;
            }
        }
        .wrap {
            //width: 198px;
            //margin: 10px 0 10px 8px;
        }
        .scrollable {
            overflow: hidden;
            overflow-y: scroll;
            height: 300px;
        }
    }
    &.content {
        float: right;
        width: 772px;
        margin-right: 10px;
        margin-top: 10px;
        padding-bottom: 14px;
        position: relative;
    }
}

.search {
    position: absolute;
    top: 39px;
    right: 8px;
    background: $grey-input;
    border: 1px solid $grey-canon;
    width: 224px;
    height: 28px;
    @include border-radius(4px);
    input {
        height: 26px;
        margin: 0 0 0 4px;
        width: 184px;
    }
    button {
        height: 30px;
        width: 30px;
        position: absolute;
        right: 0;
        top: -1px;
    }
}

form {
    input {
        border: none;
        background: none;
        color: $grey-dark;
        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0 50px $grey-canon inset;
            /* Change the color to your own background color */
            -webkit-text-fill-color: #333;
        }
        &:-webkit-autofill:focus {
            -webkit-box-shadow: 0 0 0 50px $grey-canon inset;
            -webkit-text-fill-color: #333;
        }
    }
    button,
    a.button {
        border: none;
        background: $red-canon;
        color: $white;
        @include border-radius(0px 4px 4px 0px);
        &:hover {
            background: darken($red-canon, 5%);
        }
    }
}

#group_lists {
    .homegroup_browse,
    .treeview_browse {
        width: 30px;
        height: 30px;
        display: block;
        margin-bottom: 8px;
        text-indent: -999999px;
    }
    .homegroup_browse {
        background: darken($grey-input, 10%) url(../images/icon.home.png) center center no-repeat;
        float: left;
        &:hover {
            background: darken($grey-input, 15%) url(../images/icon.home.png) center center no-repeat;
        }
    }
    .treeview_browse {
        background: darken($grey-input, 10%) url(../images/icon.tree.png) center center no-repeat;
        float: right;
        &:hover {
            background: darken($grey-input, 15%) url(../images/icon.tree.png) center center no-repeat;
        }
    }
}

.device_disconnected td {
    background-color: #c80505 !important;
    color: white !important;
}

.button.delete, .button.back {
    &.left {
        float: left !important;
        padding: 0 20px !important;
    }
    &.right {
        float: right !important;
        padding:13px 10px !important;
        margin: 10px 0 50px 5px !important;
    }
    border-radius: 4px !important;
    clear: unset !important;
    margin-left: 5px !important;
    font-size: 15px !important;
}

.login_button.add-contacts.button.round4.theme-button.right:disabled {
    background-color: gray !important;
    cursor: default;
}

.group_list {
    li {
        margin: 0 0 4px 0;
        height: 28px;
        line-height: 28px;
        background: darken($grey-input, 5%);
        font-size: 12px;
        overflow: hidden;
        @include border-radius(0 4px 4px 0);
        &.back {
            background: $grey-dark url(../images/icon.back.png) 0px 0px no-repeat;
            &:hover {
                background: darken($grey-dark, 5%) url(../images/icon.back.png) 0px 0px no-repeat;
            }
            a {
                padding-left: 40px;
                color: darken($grey-input, 5%);
            }
        }
        &.group {
            background: darken($grey-input, 5%) url(../images/icon.group.png) 0px 0px no-repeat;
            &:hover {
                background: darken($grey-input, 10%) url(../images/icon.group.png) 0px 0px no-repeat;
            }
            a {
                font-weight: 700;
                padding-left: 40px;
            }
        }
        &.parent {
            background: $red-canon url(../images/icon.group.png) 0px 0px no-repeat;
            &:hover {
                background: darken($red-canon, 5%) url(../images/icon.group.png) 0px 0px no-repeat;
            }
            a {
                padding-left: 40px;
                color: $white;
            }
        }
        &.device {
            @include border-radius(0px 4px 4px 0px);
            background: darken($grey-input, 5%) url(../images/icon.printer.png) 0px 0px no-repeat;
            &:hover {
                background: darken($grey-input, 10%) url(../images/icon.printer.png) 0px 0px no-repeat;
            }
            a {
                padding-left: 40px;
                color: $grey-dark;
            }
        }
        &:hover {
            background: darken($grey-input, 15%)
        }
        a {
            padding: 0 6px;
            color: $grey-dark;
            display: block;
        }
    }
}

table {
    width: 100%;
    margin-top: 22px;
    tr {
        &.filtered {
            display: none;
        }
        &.pag-hidden {
            display: none;
        }
        th {
            padding: 10px 6px;
            font-size: 15px;
            font-weight: 600;
            background: darken($grey-input, 5%);
            color: lighten($grey-dark, 5%);
            &.center {
                text-align: center;
            }
        }
        td {
            font-size: 13px;
            padding: 10px 6px;
            background: $white;
            color: #797979;
            &.center {
                text-align: center;
            }
            strong {
                font-weight: bold;
            }
            span {
                display: block;
                &.usage_bar {
                    background: #C1C1C1;
                    height: 22px;
                    display: block;
                    .usage {
                        background: #1BA6C5;
                        height: 22px;
                        line-height: 22px;
                        color: #fff;
                        text-align: right;
                        font-size: 11px;
                        width: 1px;
                        overflow: hidden;
                    }
                    .usage {
                        &.color_band1 {
                            background: #65c805;
                        }
                        &.color_band2 {
                            background: #1BA6C5;
                        }
                        &.color_band3 {
                            background: #C59D1B;
                        }
                        &.color_band4 {
                            background: #C80505;
                        }
                    }
                }
                &.status {
                    font-weight: bold;
                    text-align: center;
                    background: #C80505;
                    color: #F5F5F5;
                    height: 20px;
                    line-height: 20px;
                    &.status_ok {
                        background: #58A30A;
                        color: #fff;
                    }
                }
            }
            &.col_period {
                width: 135px;
            }
            &.col_billing-date span,
            &.col_month-open span,
            &.col_rental span {
                display: inline;
                font-size: 11px;
                font-style: italic;
                vertical-align: super;
                color: #8e8e8e;
                padding-left: 5px;
            }
            .detail {
                margin: 8px auto;
                background: #B5B5B5;
                @include border-radius(4px);
                dt {
                    font-weight: bold;
                    background: #B5B5B5;
                    display: block;
                    float: left;
                    width: 100px;
                    padding: 4px 0;
                    text-align: center;
                    color: #fff;
                    @include border-radius(4px);
                }
                dd {
                    width: 630px;
                    background: #e0e0e0;
                    padding: 4px 4px 4px 10px;
                    float: left;
                }
            }
        }
        .col_itemcode {
            width: 150px;
        }
        &.even {
            td {
                background: $grey-input;
            }
        }
        &.select {
            td {
                background: #f5efef; 

            }    
        }
        &.unfiltered-even {
            td {
                background: $grey-input;
            }
        }
        &.unfiltered-odd {
            td {
                background: $white;
            }
        }
        td.col_timestamp,
        th.col_timestamp {
            width: 150px;
        }
        td.col_timestamp_short,
        th.col_timestamp_short {
            width: 110px;
            padding-left: 20px;
        }
        &.part_row,
        &.usage_history,
        &.coverage_usage_history,
        &.warnerror,
        &.cost_row,
        &.invoice_row {
            cursor: pointer;
            td.col_code,
            td.col_month-open,
            td.col_timestamp_short,
            td.expandable {
                background-image: url(../images/icon.openclosed.png);
                background-repeat: no-repeat;
                background-position: 3px 13px;
                padding-left: 20px;
            }
            td.expandable {
                background-position: 3px 10px;
            }
            &.open {
                background-position: 3px -31px;
            }
            &:hover td {
                background-color: darken(#efefef, 5%);
                cursor: pointer;
            }
        }
        &.warnerror.open {
            td.col_timestamp_short {
                background-position: 3px -37px;
            }
        }
        tr.warnerror td.col_timestamp_short {
            padding-left: 20px;
            background-position: 3px 13px;
        }
    }
    &.selection {
        td.action {
            a {
                display: none;
            }
        }
        tr:hover {
            cursor: pointer;
            td {
                background: darken($white, 10%);
                &.even {
                    background: darken($grey-input, 5%);
                }
            }
            td.action {
                a {
                    display: inline-block;
                }
            }
        }
    }
    &.tooltip_usage {
        margin: 4px 0;
        td {
            padding: 0;
            background: #fff;
        }
    }
}

table.admins-table tr, table.engineers-table tr {
    td:nth-child(1) {
        max-width: 215px;
        overflow: hidden;
    }
    td:nth-child(2) {
        max-width: 215px;
        overflow: hidden;
    }
    td:nth-child(3) {
        max-width: 215px;
        overflow: hidden;
    }
    td:nth-child(4) {
        max-width: 80px;
        overflow: hidden;
    }
}
table.distributors-table tr {
    td:nth-child(1) {
        max-width: 295px;
        overflow: hidden;
    }
    td:nth-child(2) {
        max-width: 130px;
        overflow: hidden;
    }
    td:nth-child(3) {
        max-width: 80px;
        overflow: hidden;
    }
    td:nth-child(4) {
        max-width: 80px;
        overflow: hidden;
    }
    td:nth-child(5) {
        max-width: 80px;
        overflow: hidden;
    }
    td:nth-child(6) {
        max-width: 80px;
        overflow: hidden;
    }
}
table.resellers-table tr, table.sites-table tr {
    td:nth-child(1) {
        max-width: 230px;
        overflow: hidden;
    }
    td:nth-child(2) {
        max-width: 230px;
        overflow: hidden;
    }
    td:nth-child(3) {
        max-width: 80px;
        overflow: hidden;
    }
    td:nth-child(4) {
        max-width: 80px;
        overflow: hidden;
    }
    td:nth-child(5) {
        max-width: 80px;
        overflow: hidden;
    }
}
table.companies-table tr {
    td:nth-child(1) {
        max-width: 210px;
        overflow: hidden;
    }
    td:nth-child(2) {
        max-width: 210px;
        overflow: hidden;
    }
    td:nth-child(3) {
        max-width: 130px;
        overflow: hidden;
    }
    td:nth-child(4) {
        max-width: 80px;
        overflow: hidden;
    }
    td:nth-child(5) {
        max-width: 80px;
        overflow: hidden;
    }
}
table.users-table tr {
    td:nth-child(1) {
        max-width: 230px;
        overflow: hidden;
    }
    td:nth-child(2) {
        max-width: 230px;
        overflow: hidden;
    }
    td:nth-child(3) {
        max-width: 130px;
        overflow: hidden;
    }
    td:nth-child(4) {
        max-width: 130px;
        overflow: hidden;
    }
}
table.admin-user-table tr {
    td:nth-child(1) {
        max-width: 200px;
        overflow: hidden;
    }
    td:nth-child(2) {
        max-width: 200px;
        overflow: hidden;
    }
    td:nth-child(3) {
        max-width: 335px;
        overflow: hidden;
    }
}

.radio-inline {
    float: left;
    display: inline-block !important;
    text-align: left;
    text-transform: none !important;
    font-weight: normal !important;
    input {
        width: 13px !important;
    }
}

.labeled-input {
    display: inline-block;
    .input-label-before {
        width: 28px;
        height: 12px;
        padding: 10px 5px;
        background-color: #ECECEC;
        border: 3px solid#d7d7d7;
        float: left;
        margin: 0 !important;
    }
    input {
        width: 58px !important;
        text-align: left !important;
    }
    &.coverage-band {
        display: inline-block;
        .input-label-after {
            width: 18px;
            height: 12px;
            padding: 10px 5px;
            background-color: #ECECEC;
            border: 3px solid#d7d7d7;
            float: left;
            margin: 0 !important;
        }
        input {
            width: 35px !important;
            float: left;
            &.two {
                width: 25px !important;
            }
        }
    }
    &.extradata {
        display: inline;
    }
}

.cost-row.cost-row-billing {
    margin-bottom: 72px;
}

.cost-row.disabled,
.invoice-row.disabled,
.data_item.disabled {
    opacity: 0.5;
}

[data-module='costing'] {
    .data_info {
        .data_item {
            width: 143px;
            height: 180px;
            padding: 15px 10px 0;
            box-sizing: border-box;
            margin-right: 10px;
            float: left;
            background: #ececec;
            &.item_1 {
                margin-left: 3px;
            }
            &.item_5 {
                margin-right: 0;
            }
            &.item_6 {
                margin-left: 3px;
                margin-top: 10px;
                padding-left: 0;
                padding-right: 0;
                height: 130px;
                width: 755px;
                box-sizing: border-box;
                .cost-row {
                    float: left;
                    padding: 0 7px 0 10px;
                    box-sizing: border-box;
                    &.cost-row-dutye {
                        margin-right: 0;
                    }
                    &.two {
                        width: 143px;
                        padding: 0 10px 0;
                        margin-right: 10px;    
                    }
                    input {
                        width: 105px;
                    }
                }
            }
            &.item_7 {
                margin-left: 3px;
                margin-top: 10px;
                width: 449px;
                height: 105px;
                padding: 10px;
                &.EU {
                    width: 296px;
                    .labeled-input.extradata input {
                        width: 256px !important; 
                    }
                }
                input {
                    float: left;
                    width: 258px;
                }
                .labeled-input.extradata input {
                    float: left;
                    width: 145px !important; 
                    margin-right: 10px;
                }
                .labeled-input.extradata2 input {
                    float: left;
                    width: 145px !important; 
                    margin-right: 0;}
                p {
                    float: left; 
                }
            }
            &.item_8 {
                margin: 10px 0px 0 0px;
                height: 105px;
                width: 296px;
                padding-top: 10px; 
                &.EU {
                    width: 449px;
                    input {
                        width: 410px;
                    }
                }
                input {
                    float: left;
                    width: 258px;
                }
                p {
                    float: left;
                }
            }
            &.item_9 {
                margin: 10px 10px 0 3px;
                width: 250px;
                height: 33px;
                padding: 0 10px;
                input {
                    width: 334px;
                    float: left; }
                p {
                    width: 70px;
                    margin: 0;
                    float: left;
                }
            }
            &.item_10 {
                margin: 10px 0 0 0px;
                width: 372px;
                height: 105px;
                padding: 10px 10px 0; 
                input {
                    width: 334px;
                    float: left; }
                p {
                    width: 70px;
                    margin: 0;
                    float: left;
                }
            }
            label {
                text-align: center;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
                margin-bottom: 10px;
                &.checkbox-inline {
                    display: inline-block !important;
                    float: left;
                    position: relative;
                    text-align: left !important;
                    width: auto;
                    font-weight: normal;
                    text-transform: none;
                    padding-top: 10px;
                    input {
                        float: left;
                        width: 10px !important;
                        margin: 0 10px 0 0;
                    }
                }
            }
            input,
            select {
                width: 105px;
                background: #d7d7d7;
                padding: 10px;
                border: none;
                text-align: center;
                font-size: 13px;
            }
            select {
                width: 125px;
                height: 38px;
            }
            p {
                display: block;
                margin: 10px 0;
                font-size: 11px;
                line-height: 15px;
                i {
                    font-size: 10px;
                    display: block;
                    margin-top: 20px;
                }
                em,
                i {
                    font-style: italic;
                }
                strong {
                    font-weight: bold;
                }
            }
        }
        .data_update {
            display: block;
            float: right;
            width: 140px;
            height: 34px;
            background: $red-canon;
            color: #fff;
            text-align: center;
            line-height: 34px;
            margin: 10px 2px 3px 0;
            font-weight: bold;
            cursor: pointer;
        }
    }
    small {
        font-size: 10px;
    }

}

/* Start of Invoices */
[data-module='invoices'] {
    .data_info  {
        padding-bottom: 30px;
        .data_item {
            margin-left: 3px;
            margin-top: 10px;
            padding-left: 0;
            padding-right: 0;
            height: 60px;
            width: 755px;
            box-sizing: border-box; 
            label {
                text-align: center;
                font-weight: bold;
                text-transform: uppercase;
                display: block;
                margin-bottom: 10px; 
            }

            input {
                max-width: 105px;
                background: #d7d7d7;
                padding: 10px;
                border: none;
                text-align: center;
                font-size: 13px; 
            }

            .invoice-row {
                float: left;
                padding: 0 7px 0 10px;
                box-sizing: border-box; 
        
                &.invoice-row-dutye {
                    margin-right: 0 !important; 
                }
            }
        }
    }
}
  /* End of Invoices */

pre {
    font-family: Consolas, monospace;
    font-size: 11px;
    &.inf {
        margin: 14px 0;
        padding: 10px 0;
    }
}

.head_divider {
    width: 48%;
    min-height: 200px;
    &.id_l {
        float: left;
        .wrap {
            padding: 0 5px 0 0;
        }
    }
    &.id_r {
        float: right;
        border-left: 1px dashed #d6d6d6;
        padding-left: 14px;
        .wrap {
            padding: 0 0 0 5px;
        }
    }
    &.id_1 {
        width: 100%;
        .wrap {
            padding: 10px 14px 14px 14px;
        }
    }
    .wrap {
        padding: 0 14px 14px 14px;
    }
}

.download_inf,
.export_data {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    background: #c80505;
    padding: 0 10px;
    border-radius: 4px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    text-decoration: none;
    font-size: 13px;
    text-align: center;
    font-weight: normal;
    .export_data .bi.bi-download {
        padding: 10px 5px 0;
    }
    &:hover {
        background: #b10404;
    }
    &.billing {
        right: auto;
        left: auto;
        position: static;
        float: left;
    }
    .bi.bi-download {
        padding-right: 5px;
    }
}

.export_data {
    left: 0px;
    right: auto;
}

#login_container {
    background: $grey-input;
    width: 370px;
    margin: 20px 0;
    .form_element {
        width: 320px;
        margin-bottom: 24px;
        label {
            margin: 0 0 8px;
            color: $red-canon;
            font-size: 15px;
            display: block;
            font-weight: 600;
        }
        .input_field {
            padding: 5px 10px;
            background: darken($grey-input, 10%);
            input {
                width: 300px;
                height: 30px;
                background: none;
                font-size: 14px;
                color: $grey-dark;
                font-weight: 300;
            }
        }
    }
    button {
        font-size: 15px;
        font-weight: 500;
        padding: 10px;
        cursor: pointer;
        float: right;
    }
    .wrap {
        padding: 25px;
        @include clearfix();
    }
    p a {
        color: $red-canon;
    }
}

.view-types {
    position: absolute;
    top: 10px;
    right: 10px;
    li {
        width: 55px;
        height: 30px;
        display: block;
        float: right;
        line-height: 27px;
        text-align: center;
        background: #E4E4E4;
        cursor: pointer;
        &.current {
            background: #5B5B5B;
            a {
                color: #fff;
                font-weight: bold;
            }
        }
        a {
            color: #666;
            text-decoration: none;
            font-size: 12px;
        }
    }
}

.view-type-list {
    @include border-radius(4px 0 0 4px);
}

.view-type-map {
    @include border-radius(0 4px 4px 0);
}

.mapview {
    display: none;
    height: 500px;
    width: 100%;
    margin-top: 20px;
}

.infoBox {
    padding: 15px;
    td {
        padding: 2px 0;
        &.title {
            font-weight: bold;
            width: 100px;
        }
    }
}

.overlay {
    width: 700px;
    position: absolute;
    margin: 0 0 0 -350px;
    top: 200px;
    left: 50%;
    background: none repeat scroll 0 0 #EEEEEE;
    z-index: 20;
    display: none;
}

#device_identifier {
    position: relative;
    margin-bottom: 15px;
    dl {
        float: left;
        margin: 8px 18px 0 0;
    }
    dt {
        background: #fff;
        font-weight: bold;
        height: 20px;
        line-height: 18px;
        float: left;
        text-align: center;
        width: 20px;
        display: block;
        margin-right: 8px;
        color: $red-canon;
    }
    dd {
        float: left;
        font-size: 15px;
        height: 20px;
        line-height: 18px;
        color: #545353;
    }
}

.progressive-nav {
    position: relative;
    width: 792px;
    margin-left: 308px;
    padding-left: 5px;
    min-height: 37px;
           
    button {
        position: absolute;
        height: 33px;
        right: 0;
        top: 5px;
        padding: 0 15px;
        border: 0;
        outline: none;
        background-color: #f7f7f7;
        color: #7a7a7a;
        &.current{ 
        background-color: #fff;
            a {
                color: #c80505;                
            }
            &:hover {
                background-color: #fff;
            }


        }
        cursor: pointer;
        a  {
            margin-right: 5px;
        }
        &::after {
            content: attr(count);
            position: absolute;
            width: 16px;
            height: 16px;
            left: -8px;
            top: -7px;
            text-align: center;
            background-color: #666;
            color: #fff !important;
            font-size: 11px;
            line-height: 16px;
            border-radius: 50%;
            font-weight: bold;
          }
    }
            
    button:hover {
        background: #dedede;
        color: #666;
    
    }
            
    button:hover::after {
        transform: scale(1.075);
    }

    .visible-links {
        display: inline-block;
    }
    .visible-links	li {
        display: inline-block;
        margin: 5px 0 0 0;
        &.current {
            a,
            a:hover {
                background: #fff;
                color: #c80505 !important;
                border-bottom: 1px solid transparent;
            }
        }
        a {
            background: #f7f7f7;
            display: block;
            padding: 0 14px;
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            color: #7a7a7a;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            &:hover {
                background: #dedede;
                color: #666;
            }
        }
    }
        
    .hidden-links {
        position: absolute;
        right: 0px;
        min-width: 200px;
        top: 38px;
        z-index: 9999999;
        border-radius:  0 0 5px 5px !important;
        box-shadow: 0 3px 5px 0 #00000029;
    }		
    .hidden-links li {
        &.current {
            a,
            a:hover {
                background: #fff;
                color: #c80505 !important;
            }
        }
        a {
            display: block;
            background-color: #f7f7f7;
            padding: 6px 15px;
            min-width: 100px;
            line-height: 32px;
            font-size: 12px;
            color: #7a7a7a;
            border-bottom: 1px solid #e2e2e2;
            &:hover {
                background: #dedede;
                color: #666;
            }
        }
        
        &:last-child a {
            border-radius:  0 0 5px 5px !important;
            border-bottom: 1px solid transparent !important;
        }
    }	
        
   .hidden {
        visibility: hidden;
    }
}

ul#device_nav {
    width: 772px;
    margin: 0 0 0 308px;
    background: none !important;
    display: block;
    min-height: 38px;
    li {
        float: left;
        margin: 5px 6px 0 0;
        &.current {
            a,
            a:hover {
                background: #fff;
                color: #c80505;
                font-weight: bold;
                border-bottom: 1px solid transparent;
            }
        }
        a {
            background: #f7f7f7;
            display: block;
            padding: 0 14px;
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            color: #7a7a7a;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            &:hover {
                background: #dedede;
                color: #666;
            }
        }
    }
}

.graph_controls {
    margin: 8px 0;
    width: 100%;
    &.top-18px {
        margin-top: -18px;
        margin-bottom: 0;
    }
    li {
        float: left;
        margin-right: 10px;
        height: 20px;
        &.right-of-chart{
            margin-left: 270px;
            background: #dadada;
            border-radius: 4px;
        }
        &.right {
            float: right;
            margin-right: 0;
            background: #dadada;
            padding-inline: 10px;
            border-radius: 4px;
        }
    }
    a {
        text-decoration: none;
        font-size: 12px;
        color: #333333;
        &:hover {
            color: #C80505;
        }
        &.popout {
            padding: 0 10px;
        }
    }
}

.head_ink,
.head_info,
.device_ink,
.device_info {
    margin-bottom: 10px;
    padding: 10px 36px 10px 10px;
    background: #F0F0F0;
    position: relative;
    &.usa {
        .color {
            &.tall {
                height: 120px;
            }
        }
    }
    .color {
        height: 45px;
        width: 16px;
        display: block;
        position: absolute;
        top: 30px;
        right: 10px;
        &.tall {
            height: 80px;
        }
    }
    .changes {
        color: #999;
        font-weight: normal;
        margin-left: 2px;
    }
    .selectable {
        position: absolute;
        top: 10px;
        height: 16px;
        width: 16px;
        display: block;
        right: 10px;
        background: #EAEAEA;
        cursor: pointer;
        &.selected {
            background: url(../images/icon.selected.png) no-repeat center center #fff;
        }
    }
}

.modal-container {
    .header{
        padding-bottom: 10px;
    }
    clear: both;
    .inventory-modal { 
        &.option-ink {
            margin-top: 25px;
            width: 50%;
        }
        .lnr.lnr-drop, .bi.bi-droplet, .lnr.lnr-trash, .bi.bi-trash, .lnr.lnr-file-empty, .bi.bi-file-earmark, .lnr.lnr-frame-contract , .bi.bi-fullscreen-exit {
            color: #fff;
            border-radius: 100%;
            padding: 5px;
            margin-right: 5px;
        }
        .label {
            line-height: 30px;
            width: 44%;
            float: left;
            .ink-name {
                line-height: 16px;
                vertical-align: top;
            }
        }
        .threshold {
            width: 56%;
            float: left;
            input {
                background-color: #f1f1f1;
                padding: 5px;
                float: left;
                &[type=number] {
                    width: 50px;
                }
                &[type=date] {
                    width: 125px;
                    margin-left: 3px;
                }
                &.margleft3 {
                    margin-left: 3px;
                }
            }
            .date-shipped {
                width: 140px;
            }
            .shipped-total {
                width: 45%;
                .quantity {
                    width: 50%;
                    float: left;
                    margin-top: 10px;
                }
            }
            .float-left {
                float: left;
            }
        }
        .clear-both {
            clear: both;
        }
    }
}

.device_inks {
    .color {
        height: 26px;
    }
}

.device_ink {
    &.usa {
        .color {
            height: 60px;
        }
    }
}

.stat_col_1,
.stat_col_2,
.stat_col_3 {
    width: 370px;
    float: left;
    margin: 0 0 0 0;
}

.stat_col_1 {}

.stat_col_2 {
    float: right;
}

.stat_col_3 {}

.data_info {
    padding: 6px 0;
    .device_stat {
        dt {
            background: #B5B5B5;
        }
        dd {
            width: 110px;
            margin-left: -3px;
        }
    }
    .memberslist & {
        .data_info_block {
            width: 300px;
        }
        .device_stat {
            width: 300px;
            dt {}
            dd {
                text-align: center;
            }
        }
    }
}

.data_info_block {
    width: 210px;
    float: left;
    margin-right: 18px;
    &.last {
        margin-right: 0;
    }
}

.device_stat {
    font-size: 12px;
    margin: 0 0 4px 0;
    &.double {
        dt {
            width: 150px;
        }
        .data_info & {
            dd {
                width: 150px;
            }
        }
    }
    &.wide {
        dt {
            width: 100%;
        }
        dd {
            width: 100%;
            @include border-radius(0 0 4px 4px);
        }
    }
    dt {
        background: #7D7D7D;
        color: #fff;
        width: 100px;
        float: left;
        height: 26px;
        line-height: 26px;
        span {
            padding: 0 0 0 8px;
        }
    }
    dd {
        background: #e0e0e0;
        color: #4f4f4f;
        width: 130px;
        float: left;
        height: 26px;
        line-height: 26px;
        @include border-radius(0 4px 4px 0);
        span {
            padding: 0 0 0 8px;
        }
    }
    &.head {
        dt {
            background: #4391C1;
        }
    }
    strong {
        font-weight: bold;
    }
}

.chart_options {
    li {
        font-size: 12px;
        float: left;
        margin: 0 4px 6px 0;
        a {
            color: #7A7A7A;
            text-decoration: none;
            display: block;
            background: #D6D6D6;
            padding: 4px 10px;
            &:hover {
                color: #656464;
            }
        }
        &.current {
            a,
            a:hover {
                color: #C80505;
                background: #FFFFFF;
            }
        }
    }
}

#total_graph_usage {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 11px;
    width: 200px;
    text-align: right;
    color: #666;
    &.twothirds {
        right: 216px;
    }
    .value {
        color: #333;
    }
}

.chart_wrap {
    padding: 10px;
    background: #E0E0E0;
    position: relative;
    &.half {
        width: 45%;
        float: left;
    }
    &.onethird {
        width: 20%;
    }
    &.twothirds {
        width: 70%;
        float: left;
    }
    &.perc50 {
        width: 50%;
        float: left;
    }
    &.summary_waste {
        width: 138px;
        float: right;
        .chart_waste {
            height: 200px;
        }
    }
    &.summary_ink {
        width: 320px;
        float: left;
    }
    &.heads {
        margin: 10px 0;
    }
    .loading {
        width: 16px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
        background: url(../images/loading.small.gif) no-repeat;
    }
    .chart_inklevels {
        height: 200px;
    }
    .chart_waste {
        height: 250px;
    }
    .chart_media {
        height: 150px;
    }
    .chart_coverage {
        height: 150px;
    }
    .chart_ink {
        height: 250px;
    }
    .chart_head_current,
    .chart_head_total {
        height: 150px;
    }
}

#waste_level_change {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 11px;
    width: 200px;
    text-align: right;
    color: #666;
    .value {
        color: #333;
    }
}

.valid {
    color: #3DA040;
}

.invalid {
    color: #C80505;
}

.none {
    display: none;
}

.valid.hidden,
.invalid.hidden {
    display: none;
}

.tab_content {
    background: #eeeeee;
    overflow: hidden;
    @include border-radius(0 0 4px 4px);
    .wrap {
        padding: 20px 10px 10px 10px;
        position: relative;
        .withnav {
            padding: 2px 10px 2px;
        }
        .mid {
            padding: 10px 10px 10px 10px;
        }
    }
}

.history-color {
    margin: 10px 0;
    background: #EFEFEF url(../images/icon.openclosed.png) 3px 13px no-repeat;
    color: #4F4F4F;
    font-weight: bold;
    font-size: 12px;
    line-height: 19px;
    cursor: pointer;
    &:hover {
        background-color: #F9F9F9;
    }
    .open {
        background: #F9F9F9 url(../images/icon.openclosed.png) 3px -33px no-repeat;
    }
    span {
        width: 20px;
        height: 20px;
        float: left;
        margin-right: 10px;
        margin-left: 10px;
    }
    em {
        float: right;
        font-size: 12px;
    }
    .wrap {
        padding: 10px;
    }
}

.blocknav {
    height: 75px;
    margin: 6px 0;
    border-top: 3px solid #e4e3e3;
    border-bottom: 3px solid #e4e3e3;
    background: #F4F4F4;
    li {
        width: 120px;
        float: left;
        height: 67px;
        margin-top: 4px;
        border-right: 1px solid #d6d6d6;
        display: table;
        &.current {
            a,
            a:hover {
                background: #f8f8f8;
                color: #404040;
                font-weight: bold;
            }
        }
        a {
            display: block;
            padding: 0 10px 0;
            text-align: center;
            height: 67px;
            font-size: 12px;
            text-decoration: none;
            color: #858585;
            text-transform: uppercase;
            display: table-cell;
            vertical-align: middle;
            line-height: 15px;
            &:hover {
                background: #f8f8f8;
                color: #656565;
            }
        }
    }
}

.head_ink_stat,
.head_info_stat,
.device_ink_stat,
.device_info_stat {
    display: block;
    width: 100%;
    margin: 0 0 5px;
    font-weight: bold;
    font-size: 12px;
    border-bottom: 1px solid #E2E2E2;
    padding-bottom: 2px;
    color: #8E8E8E;
    font-weight: 600;
    &.last {
        margin-bottom: 0;
    }
    &.heading {
        font-size: 15px;
        border-bottom: none;
        margin-bottom: 8px;
        color: #666;
        font-weight: 600;
    }
    .value,
    .sheet-value {
        font-weight: normal;
        display: block;
        float: right;
        margin-right: 4px;
        text-align: right;
        width: 120px;
    }
}

dl.device_stat dd span.coverage-value {
    width: 44% !important;
    display: inline-block;
}

.w230.summary {
    width: 250px;
}

.w230.summary dd {
    width: 150px;
}

.w520.summary {
    width: 500px;
}

.summary .device_stat.wide dd {
    width: 100%;
}

.head_info_stat {
    .value {
        width: auto;
        &.valid {
            color: #3DA040;
        }
        &.invalid {
            color: #C80505;
        }
        &.unknown {
            color: #AEAEAE;
        }
    }
}

body.fullscreen {
    overflow: hidden;
}

#popout {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background: #999;
    z-index: 50;
    .popchart {
        width: 100%;
        background: #ccc;
    }
    .popout_close {
        display: block;
        text-align: center;
        height: 50px;
        width: 50px;
        font-weight: bold;
        line-height: 50px;
        background: #e2e2e2;
        color: #C80505;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 20;
    }
}

.mpa_col_0 {
    margin: 10px 0;
    .gmap {
        width: 100%;
        height: 300px;
        margin-bottom: 10px;
    }
}

.mpa_input {
    background: none repeat scroll 0 0 #eeeeee;
    padding: 4px;
    margin-bottom: 10px;
    input {
        width: 630px;
        margin: 0;
        float: right;
        border: none;
        display: block;
        height: 22px;
        line-height: 22px;
        font-size: 12px;
        background: #eeeeee;
    }
    label {
        font-size: 11px;
        float: left;
        width: 110px;
        height: 18px;
        line-height: 24px;
        margin-left: 4px;
        font-weight: bold;
        color: #9C9C9C;
    }
}

.mpa_col_1,
.mpa_col_2 {
    width: 375px;
    label {
        width: 90px;
    }
    input {
        width: 270px;
    }
    &.info {
        padding: 10px 0;
        h3.sub {
            font-size: 13px;
            margin: 10px 0;
        }
    }
}

.add-contact {
    display: none;
    width: 100%;
    margin-bottom: 0;
    .mpa_input {
        width: 238px;
        @include inline-block;
        label {
            width: auto;
            @include inline-block;
            margin-right: 5px;
        }
        input {
            width: 144px;
            float: left;
            &.contact-name {
                width: 180px;
            }
            &.contact-email {
                width: 180px;
            }
            &.contact-phone {
                width: 140px;
            }
        }
    }
    a {
        float: right;
        @include inline-block;
        margin-top: 10px;
        width: 25px;
        text-align: center;
    }
}

.contacts-container {
    float: left;
    p.errors {
        color: #ff0000;
        padding: 15px;
        border: 1px solid #ff0000;
        @include border-radius(10px);
        margin: 0;
    }
    p.success {
        margin: 0;
    }
}

.action-button {
    &.add_device_contacts {
        display: none;
    }
    &.add-contacts {
        margin-top: 0;
        margin-bottom: 10px;
    }
}

.line {
    height: 4px;
    background: #DDDDDD;
    width: 100%;
    margin: 0 0 10px;
}

.action-button {
    display: block;
    float: right;
    font-size: 12px;
    color: #fff;
    text-align: center;
    width: 130px;
    text-decoration: none;
    background: #C80505;
    padding: 8px 0;
    margin: 6px 0 0 0;
    &.add_device_contacts,
    &.geocode {
        margin: 10px 10px 20px;
    }
    &.disabled, 
    &.disabled:hover {
        background: #999;
        cursor: default;
    }
    &:hover {
        cursor: pointer;
    }
}

.float-left {
    float: left;
  }

.system-search {
    background: $grey-input;
    border: 1px solid $grey-canon;
    padding: 4px;
    width: 250px;
    margin-left: 10px;
    border-radius: 4px;
    margin-top: 8px;
    input {
        border: none;
        width: 100%;
        background: none;
    }
}

.system-navigation {
    transition: opacity 0.3s ease;
    position: relative;
    //background: #E8E8E8;
    &.loading {
        transition: none;
        opacity: 0;
    }
    li {
        font-size: 13px;
        border-bottom: 1px solid #E8E8E8;
        //margin-bottom: 1px;
        padding-bottom: 8px;
        padding-top: 8px;
        padding-left: 10px;
        display: none;
        position: relative;
        border-left: 2px solid transparent;
        cursor: pointer;
        span {
            display: block;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 12px;
            color: $red-canon;
            margin-bottom: 2px;
            transition: background 0.5s ease, color 0.5s;
        }
        &.initial,
        &.show {
            display: block;
        }
        &.open {
            background: #e2e2e2;
            border-left: 2px solid $red-canon;
        }
        &.live-filtered {
            display: none;
        }
        &.live-keyword {
            span {
                color: red;
            }
        }
        transition: background 0.5s ease,
        color 0.5s;
        &.locked {
            position: absolute;
            top: 0;
            left: 0;
            width: 283px;
            min-height: 27px;
            z-index: 1;
            &.on {
                background: $red-canon;
                border-bottom-color: #c10101;
                span {
                    color: #ffa0a0;
                }
                color: #ffeaea;
            }
            em {
                color: #eaeaea;
            }
            &:hover {
                background: darken($red-canon, 5%);
            }
            &[data-level='2'] {
                margin-top: 44px;
                width: 277px;
            }
            &[data-level='3'] {
                margin-top: 88px;
                width: 271px;
            }
            &[data-level='4'] {
                margin-top: 132px;
                width: 265px;
            }
            &[data-level='5'] {
                margin-top: 176px;
                width: 259px;
            }
        }
        &.viewing {
            background: $grey-dark;
            border-left: 2px solid $grey-dark;
            span {
                color: white;
            }
            color: white;
            &:hover {
                background: $grey-dark;
            }
        }
        &:hover {
            background: #e2e2e2;
        }
        &.displaying {
            font-weight: bold;
            background: url(../images/eye.png) no-repeat left center;
            padding-left: 40px;
        }
    }
    [data-type='distributors'] {
        padding-left: 5px;
    }
    [data-type='resellers'] {
        padding-left: 10px;
    }
    [data-type='companies'] {
        padding-left: 15px;
    }
    [data-type='sites'] {
        padding-left: 20px;
    }
    [data-type='devices'] {
        padding-left: 25px;
    }
    // [data-level='1'] { padding-left: 8px; }
    // [data-level='2'] { padding-left: 15px; background: #f1f1f1; border-left: 2px solid $red-canon;  }
    // [data-level='3'] { padding-left: 22px; background: #f1f1f1; border-left: 2px solid $red-canon; }
    // [data-level='4'] { padding-left: 29px; background: #f1f1f1; border-left: 2px solid $red-canon; }
    // [data-level='5'] { padding-left: 36px; background: #f1f1f1; border-left: 2px solid $red-canon; }
    // [data-level='6'] { padding-left: 43px; background: #f1f1f1; border-left: 2px solid $red-canon; }
    [data-level='1'] {
        padding-left: 8px;
    }
    [data-level='2'] {
        padding-left: 14px;
        border-left: 2px solid $red-canon !important;
        background: #f1f1f1;
    }
    [data-level='3'] {
        padding-left: 20px;
        border-left: 2px solid $red-canon !important;
        background: #f1f1f1;
    }
    [data-level='4'] {
        padding-left: 26px;
        border-left: 2px solid $red-canon !important;
        background: #f1f1f1;
    }
    [data-level='5'] {
        padding-left: 32px;
        border-left: 2px solid $red-canon !important;
        background: #f1f1f1;
    }
    [data-level='6'] {
        padding-left: 38px;
        border-left: 2px solid $red-canon !important;
        background: #f1f1f1;
    }
    em {
        font-size: 11px;
        position: absolute;
        top: 6px;
        right: 0;
        margin-right: 5px;
        color: #BFBFBF;
    }
}

h3 {
    &.page-heading {
        font-size: 16px;
        color: $red-canon;
        margin: 0 0 50px 0;
        padding-bottom: 7px;
        border-bottom: 5px solid #f1f1f1;
        font-weight: bold;
    }
}

.btn {
    background: #b81e25;
    line-height: 28px;
    font-size: 15px;
    padding: 0 20px 0 10px;
    margin: 0 0 0 15px;
    border-color: #b81e25;
    box-shadow: none;
    outline: none;
    color: #fff;
    .bi.bi-plus-circle {
        padding: 7px 7px 0 0;
    }
}

.version {
    float: right;
    font-size: 13px;
    color: #9e9e9e;
    position: absolute;
    right: 14px;
    bottom: 14px;
}

.content.manage h4 {
    bottom: 14px;
}

/* ic: Privacy, Terms and Condition */

.table-links {
    width: 20%;
    height: auto;
    margin: 0 auto;
    padding: 10px;

    border: 4px solid #ebebeb;
}

.table-links > b {
    text-decoration: underline;
    display:inline-block;
    font-weight: 200;
    margin-bottom: 10px;
}

.table-links ol {
  padding-left: 30px !important;
}

.table-links ol li {
  list-style: decimal !important;
}

#privacy-terms-content {
    margin: 0 50px;
}

#privacy-terms-content p {
    font-family: "Arial", Times, serif;
    line-height: 25px;
    font-size: 1rem;
    margin: 20px;
}
  
#privacy-terms-content h4 {
    font-weight: bold;
}
  
#privacy-terms-content b {
    font-weight: 450;
}

#privacy-terms-content ul {
    list-style: disc !important;
}

#privacy-terms-content ul li {
    margin-bottom: 15px;
    margin-left: 50px;
}

#privacy-terms-content h3 {
  text-align: center !important;
  text-decoration: underline !important;
}

#privacy-terms-content .links {
  cursor: pointer;
  font-size: 0.9rem;
  margin: 12px 0;
  color: #565656; 
  font-weight: bold;
}

#privacy-terms-content a:hover {
    opacity: 0.8;
}

#privacy-terms-content .links:hover {
    opacity: 0.8;
}

.copy-right {
  text-align: center;
}

.italic-bold {
  font-style: italic; 
  font-weight: 450;
  padding: 0;
}

#Miscellaneous-Provisions li {
    list-style-type: none;
}

p.disclaimer {
    float: right;
    margin-top: 20px;
    padding-right: 10px;
    max-width: 77%;
}
  
p.disclaimernofloat {
float: none;
margin-top: 0px; 
padding-right: 0px;
max-width: none;
}   

.privacy_links_wrapper {
    text-align : left;
    padding-left : 25%;
}
  
.privacy_links {
font-size: 13px;
color: #9e9e9e;
margin-right : 5px;
bottom: 14px;
cursor: pointer; }

.display_overlay_text_div {
    position: fixed;
    z-index: 1000;
    visibility: hidden;
    width: 0px;
    height: 0px;
    left: -100px;
    top: -100px;
    background-color : #FFFFFF;
    border: 50px solid rgb(0, 0, 0);
    border: 50px solid rgba(0, 0, 0, .2);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box; /* for IE9+, Firefox 4+, Opera, Chrome */
    padding: 30px;
    box-sizing: border-box;    
    text-align : left;
    vertical-align : top;
    overflow-x: hidden;
    overflow-y: auto;
}

.close_overlay {
    position : fixed;
    top : 0px;
    right : 0px;
    margin-top : 55px;
    margin-right : 80px;
    text-align : right;
    color : #CE2229;
    font : inherit;
    font-size : 20pt;
    font-weight : bold;
    cursor : pointer;
}

.content.profile {
    form {
        margin: 30px 0;
    }
    .profile-section {
        margin-bottom: 40px;
        .section-heading {
            color: #ce2229;
            margin: 0 15px 20px;
            border-bottom: 2px solid #F3F3F3;
            padding-bottom: 10px;
        }
        .profile-col-50 {
            box-sizing: border-box;
            width: 50%;
            float: left;
            padding: 0 15px 0 15px;
        }
        .profile-col-40 {
            box-sizing: border-box;
            width: 40%;
            float: left;
            padding: 0 15px 0 15px;
        }
        .profile-col-60 {
            box-sizing: border-box;
            width: 60%;
            float: left;
            padding: 0 15px 0 15px;
        }
        .profile-col-25 {
            box-sizing: border-box;
            width: 25%;
            float: left;
            padding: 0 15px 0 15px;
        }
        .profile-fields {
            float: none;
            display: block;
            width: 100%;
        }
        .profile-fields label {
            display: block;
            width: 100%;
            font-size: 13px;
            text-transform: uppercase;
            float: none;
            margin: 12px 0;
            text-align: left;
            color: #3c3c3c;
            clear: both;
        }
        .profile-fields select {
            width: 100%;
            float: none;
            display: block;
            background: #f1f1f1;
            padding: 5px;
            margin: 0;
            box-sizing: border-box;
            border: 1px solid#f1f1f1;
        }
        .profile-fields input {
            width: 100%;
            float: none;
            display: block;
            background: #f1f1f1;
            padding: 10px;
            margin: 0;
            box-sizing: border-box;
        }
    }
    .profile-field {
        border-bottom: 1px #F3F3F3 solid;
        padding-bottom: 10px;
        clear: left;
        overflow: visible;
        width: 500px;
        margin: 0 auto 10px;
        height: 50px;
        .round4.jqtrans {
            float: left;
            margin-left: 12px;
        }
    }
    .create label, .edit label {
        color: #929292;
        display: block;
        margin: 0 0 6px;
        font-weight: 300;
        width: auto;
        font-size: inherit;
        text-transform: none;
        float: none;
        text-align: inherit;
    }
    .update button {
        padding: 0 20px;
        margin: 25px 0 10px 0;
    }
    label {
        display: block;
        width: 140px;
        font-size: 13px;
        text-transform: uppercase;
        float: left;
        margin-top: 12px;
        text-align: right;
        color: $grey-dark;
    }
    input {
        width: 250px;
        float: left;
        background: #f1f1f1;
        padding: 10px;
        margin-left: 12px;
    }
    button {
        @include border-radius(4px);
        font-size: 15px;
        padding: 10px;
        float: right;
        margin: 10px 0 50px;
    }
    .errors {
        margin: 0 0 30px;
        border: 1px solid $red-canon;
        padding: 15px;
        @include border-radius(10px);
        li {
            color: $red-canon;
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 5px;
            margin-top: 5px;
            list-style: inside disc;
            padding-left: 10px;
            &.title {
                color: $grey-dark;
                list-style: none;
                padding-left: 0;
                margin-bottom: 10px;
            }
            li {
                list-style-type: none;
                margin-left: 2px; 
            }
        }
    }
    p.success {
        color: $green-lfp;
        padding: 15px;
        border: 1px solid $green-lfp;
        @include border-radius(10px);
        margin: 20px 0;
    }
    p.error {
        color: #ff0000;
        padding: 15px;
        border: 1px solid #ff0000;
        @include border-radius(10px);
        margin: 20px 0; }
    button {
        cursor: pointer;
    }
}

.forgotten {
    .errors {
        margin: 0 0 30px;
        border: 1px solid $red-canon;
        padding: 15px;
        @include border-radius(10px);
        li {
            color: $red-canon;
            font-size: 13px;
            font-weight: normal;
            margin-bottom: 5px;
            margin-top: 5px;
            list-style: inside disc;
            padding-left: 10px;
            &.title {
                color: $grey-dark;
                list-style: none;
                padding-left: 0;
                margin-bottom: 10px;
            }
            li {
                list-style-type: none;
                margin-left: 2px;
            }
        }
        p {
            a {
                color: $red-canon;
            }
        }
    }
}

.agent-list {
    background: $grey-input;
    padding: 20px;
    margin: 20px auto;
    @include border-radius(8px);
}

.agent-info-list {
    width: 500px;
    font-size: 13px;
    margin-bottom: 9px;
    padding-bottom: 9px;
    float: left;
    border-bottom: 1px solid #E6E6E6;
    dt {
        font-weight: bold;
        float: left;
        width: 170px;
        clear: left;
    }
    dd {
        float: right;
        font-weight: normal;
        width: 320px;
    }
    &.last {
        border-bottom: none; 
        margin-bottom: 0px;
        padding-bottom: 0px; 
    }
}

@import "lang/de-de";
.export_data.devices {
    left: auto;
    right: 135px;
    top: 10px;
}

[data-module='costing'] .data_info .data_item.item_11 {
    margin: 10px 0px 0 0px;
    width: 605px;
    height: auto;
    padding: 10px;
    position: relative;
}

[data-module='costing'] .data_info .data_item.item_11 input {
    width: 565px;
}

.context-menu {
    width: auto;
    padding: 0;
}

.context-menu__link {
    padding: 8px 12px 10px;
    color: #797979;
    font-size: 14px;
}

.context-menu__link:hover {
    color: #fff;
    background-color: $red-canon;
}

.col_location {
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
}

.cost-row {
    margin-bottom: 25px;
    &-extraDataArray,
    &-extraDataArray2 
    {
      margin-bottom: 0;
    }
    
}

/*----------tooltip-----------*/


/* Tooltip container */

.tooltip {
    position: relative;
    display: inline-block;
    background-color: #797979;
    color: white;
    border-radius: 60px;
    font-weight: normal;
    width: 12px;
    height: 12px;
    font-size: 9px;
    top: -2px;
    line-height: 11px;
    text-align: center;
    margin-left: 5px;
    &.coverage-desc {
        background: transparent;
        color: #797979;
        font-size: 12px;
        font-weight: bold;
        margin-left: 0px;
    }
}

a.btn.round4.theme-button {
    padding-block: 5px;
    margin-left: 0;
    text-align: center;
}

.select2-container--default .select2-selection--multiple {
    background-color: #dedede !important;
    border: 1px solid #dedede !important;
}

.select2-container .select2-search--inline .select2-search__field {
    font-size: 14px !important;
    line-height: 22px !important;
}

.buttons-holder {
    float: left;
    clear: both;
    width: 100%;
}


/* Tooltip text */

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: rgba(40, 40, 40, 0.82);
    color: #fff;
    font-weight: normal;
    text-transform: none;
    text-align: center;
    font-size: 12px;
    line-height: 14px;
    padding: 10px;
    overflow: visible;
    position: absolute;
    z-index: 1;
    right: -5px;
    top: 18px;
    &.coverage {
        text-align: left;
        width: 180px;
    }
    &.width250 {
        width: 250px;
    }
    p {
        margin-top: 3px;
        clear: both;
        color: white;
    }
}

.tooltip .tooltiptext::before {
    content: '';
    position: absolute;
    right: 5px;
    top: -4px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #282828d1;
    clear: both;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    overflow: visible;
}

.cost-row.disabled .tooltip:hover .tooltiptext,
.data_item.disabled .tooltip:hover .tooltiptext {
    visibility: hidden;
    overflow: hidden;
}

/*ic: styles for assigning companies to engineers */
.ul-engineer-company-sites {
    margin-top : 2px;
    margin-bottom : 2px;
}
  
div.company-name-wrapper {
    background-color : transparent;
    width : 95%;
    margin : 0px;
    padding : 0px;
    padding-top : 1px;
    border : 0px;
    box-sizing: border-box;
    float : right;
}
  
.companyname {
    font-weight : bold;
    background-color : transparent;
    cursor : pointer;
}
  
.companynamedisplayed {
    font-weight : bold;
    background-color : transparent;
    font-size : 13.5px; 
}

companynamedisplayed, company-site {
    display: inline-block;
    max-width: 630px;
    overflow: hidden;
}

.company-site {
    font-size : 13px;
}
  
.engineer-company-list {
    border-radius : 10px;
    background-color : #DEDEDE;
    padding : 3px;
}
  
span.engineer-select-right {
    float : right;
    color : #3C3C3C;
    cursor : pointer;
    margin-right : 2px;
}
  
li.company-sites {
    margin-top : 2px;
    margin-bottom : 2px;
    padding : 0px;
}
  /*ic: end of styles for assigning companies to engineers */

.pb-20 {
    padding-bottom: 20px;
}

/* reports list */
.report-list {
    width: 735px;
    display: table;
    padding: 10px;
}
.report-list:hover {
    background: #e6e6e6;
}
.report-list.even {
    background: #f8f8f8; 
}
.report-list a {
  width: 735px;
  display: block;
  font-size: 16px;
  color: #797979;
}
.reports-history {
    margin-top: 20px;
    height: 800px;
    overflow: scroll;
}

// ************************* //
//        Pagination         //
// ************************* //
#set_page_dropdown_wrap {
    display: inline-flex;
    float: none;
    padding-top: 6px;
    padding-bottom: 6px;
    margin-left: 5px;
    width: 117px;
}
#go_to_page_dropdown {
    border-left: none;
    border-top: none;
    border-bottom: none;
    border-right: solid 1px rgba(34, 36, 38, 0.15);
    font-size: 11.5px;
    color: #666666;
    padding: 8px;
}
.set-page-count-label {
    font-size: 12px;
    color: #666666;
}

.sort-table {

    &.desc::after {
        content: "\f0d7";
        font-family: Icons;
        float: right;   
    }
    
    &.asc::after {
        content: "\f0d8";
        font-family: Icons;
        float: right;   
    }

    &.active {
        background: #d5d5d5;
    }
}

.checkbox-all-devices {
    padding-top: 10px;
    font-size: 14px;
    color: rgba(0,0,0,.70);
}

.search-field {
    background: #f8f8f8;
    border: 1px solid #dedede;
    padding: 4px;
    width: 250px;
    margin-left: 10px;
    border-radius: 4px;
    margin-top: 8px;
    display: inline-block;

    input {
        border: none;
        width: 100%;
        background: none;
    }
}

.buttons-field {
    width: 200px;
    display: inline-block;
    margin-top: 8px;
    margin-left: 8px;

    .filter {
        padding: 4px 8px;
        border-radius: 4px;
        margin-left: 2px;
    }
    
    .reset {
        padding: 4px 8px;
        border-radius: 4px;
        font-size: 13.33px;
        line-height: normal;
        margin-left: 2px;
    }
}

#tasks_deletion_section .profile-field {
    border-bottom: none;
    padding-bottom: 0px;
}

/* skin colour selector */
.skin-dropdown-wrapper {
    font-size: 13px;
    font-family: 'Open Sans';
    font-weight: 400;

    span {
        color: #797979;
    }
}
#skins1-button, #skins2-button {
    background: #f8f8f8;
    border: 1px solid #dedede;
    padding: 6px;
    width: 250px;
    border-radius: 4px;
    display: inline-block;
}
#skins1-menu, #skins2-menu {

    li div {
    font-size: 13px;
    font-family: 'Open Sans';
    font-weight: 400;
    color: #333333;
    }
    .ui-icon {
        background-image: none;
        padding: 0 4px;
        margin-right: 5px;
    
        &.c-1 {
            background: #FFFFFF;
        }
        &.c0 {
            background: #CE2229;
        }
        &.c1 {
            background: #8CC63E;
        }
        &.c2 {
            background: #4391C1;
        }
        &.c3 {
            background: #FF0000;
        }
        &.c4 {
            background: #8B0000;
        }
        &.c5 {
            background: #FF69B4;
        }
        &.c6 {
            background: #FF1493;
        }
        &.c7 {
            background: #C71585;
        }
        &.c8 {
            background: #FF7F50;
        }
        &.c9 {
            background: #FF8C00;
        }
        &.c10 {
            background: #FFA500;
        }
        &.c11 {
            background: #FFD700;
        }
        &.c12 {
            background: #FFFF00;
        }
        &.c13 {
            background: #BDB76B;
        }
        &.c14 {
            background: #EE82EE;
        }
        &.c15 {
            background: #DA70D6;
        }
        &.c16 {
            background: #FF00FF;
        }
        &.c17 {
            background: #BA55D3;
        }
        &.c18 {
            background: #9370DB;
        }
        &.c19 {
            background: #663399;
        }
        &.c20 {
            background: #8A2BE2;
        }
        &.c21 {
            background: #9400D3;
        }
        &.c22 {
            background: #800080;
        }
        &.c23 {
            background: #6A5ACD;
        }
        &.c24 {
            background: #483D8B;
        }
        &.c25 {
            background: #7B68EE;
        }
        &.c26 {
            background: #ADFF2F;
        }
        &.c27 {
            background: #00FF00;
        }
        &.c28 {
            background: #32CD32;
        }
        &.c29 {
            background: #90EE90;
        }
        &.c30 {
            background: #00FF7F;
        }
        &.c31 {
            background: #3CB371;
        }
        &.c32 {
            background: #2E8B57;
        }
        &.c33 {
            background: #228B22;
        }
        &.c34 {
            background: #006400;
        }
        &.c35 {
            background: #9ACD32;
        }
        &.c36 {
            background: #6B8E23;
        }
        &.c37 {
            background: #808000;
        }
        &.c38 {
            background: #556B2F;
        }
        &.c39 {
            background: #66CDAA;
        }
        &.c40 {
            background: #8FBC8B;
        }
        &.c41 {
            background: #20B2AA;
        }
        &.c42 {
            background: #008B8B;
        }
        &.c43 {
            background: #008080;
        }
        &.c44 {
            background: #00BFFF;
        }
        &.c45 {
            background: #1E90FF;
        }
        &.c46 {
            background: #6495ED;
        }
        &.c47 {
            background: #4169E1;
        }
        &.c48 {
            background: #0000FF;
        }
        &.c49 {
            background: #0000CD;
        }
        &.c50 {
            background: #000080;
        }
        &.c51 {
            background: #40E0D0;
        }
        &.c52 {
            background: #00CED1;
        }
        &.c53 {
            background: #5F9EA0;
        }
        &.c54 {
            background: #4682B4;
        }
        &.c55 {
            background: #00FFFF;
        }
        &.c56 {
            background: #D2B48C;
        }
        &.c57 {
            background: #BC8F8F;
        }
        &.c58 {
            background: #F4A460;
        }
        &.c59 {
            background: #DAA520;
        }
        &.c60 {
            background: #B8860B;
        }
        &.c61 {
            background: #CD853F;
        }
        &.c62 {
            background: #D2691E;
        }
        &.c63 {
            background: #8B4513;
        }
        &.c64 {
            background: #A52A2A;
        }
        &.c65 {
            background: #800000;
        }
        &.c66 {
            background: #778899;
        }
        &.c67 {
            background: #708090;
        }
        &.c68 {
            background: #2F4F4F;
        }
    }
}
.ui-selectmenu-menu .ui-menu.customicons .ui-menu-item-wrapper {
    padding: 0.5em 0 0.5em 3em;
}
.ui-selectmenu-menu .ui-menu.customicons .ui-menu-item .ui-icon {
    height: 24px;
    width: 24px;
    top: 0.1em;
}
#upload_file_label {
    margin-bottom: 5px;
    position: relative;
    display: block;
    color: #3c3c3c;
}
#remove_logo_label {
    display: inline-block;
    width: auto;
}
#remove_logo {
    display: inline-block;
    position: relative;
    margin-left: 12px;
    top: 2px;
    width: auto;
}
#custom_skin_form_button {
    padding-top: 5px;
    padding-bottom: 5px;
}
#upload_logo_input {
    width: 299px;
    background: none;
}
p.error {
    color: #ff0000;
    padding: 15px;
    border: 1px solid #ff0000;
    @include border-radius(10px);
    margin: 20px 0;
    &.width340 {
        width: 340px;
    }
}
.mh120 {
    min-height: 120px;
}
.img-responsive {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
#skins1-menu, #skins2-menu {
    max-height: 208px;
}
button.btn.update-branding:disabled {
    opacity: 50%;
}
h4#top_title.sticky {
    position: fixed;
    top: 0;
    right: 10%;
    width: 756px;
    height: 28px;
    padding: 10px;
    margin-top: 0;
}

// Footer logo & copyright
.footer-logo-copyright-border {
    border-bottom: 60px solid #efefef !important;
}
.footer-logo-copyright {
    width: 100%;
    position: absolute;
    bottom: -70px;
}
.footer-logo {
    position: relative;
    width: 150px;
    float: left;
    left: 4px;
    top: -15px;
}
.footer-copyright {
    position: relative;
    right: 14px;
    float: right;
    top: 15px;
    font-family: 'Open Sans';
    font-size: 10px;
    color: #898787;
}

// EXTRA STYLING //
.ml-15 {
    margin-left: 15px;
}

h3.sub span {
    word-break: break-word;
}