// HELPERS
@import "mixins";

.round4 {
	@include border-radius(4px);
}

.relative {
	position: relative;
}
.clearfix {
	@include clearfix();
}
.left {
	float: left;
}
.content .profile-field.checkbox-inline input {
    width: auto !important;
    margin-top: 11px;
}
.right {
	float: right;
}
.report input {
	width: 94%;
    float: left;
    background: #f1f1f1;
    padding: 10px;
    margin-right: 10px;
}
.report button {
    padding: 10px 15px;
}
.hide { display: none!important;}
.ta-right {
	text-align: right;
}
.w230 {
	width: 230px;
}
.w410 {
	width: 410px;
}
.w520 {
	width: 520px;
}
.mtop10 {
	margin-top: 10px;
}
.mtop20 {
	margin-top: 20px;
}
.mbtm10 {
	margin-bottom: 10px;
}
.mright10 {
	margin-right: 10px;
}
.no_margin {
	margin: 0 !important;
}
.transparent_background {
	background-color: transparent !important;
}
.no_padding {
	padding: 0 !important;
} 