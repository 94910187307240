// POPUPS

#blackout {
	width: 100%;
	height: 500px;
	background: rgba(#000000, 0.6);
	position: absolute;
	top: 0;
	left: 0;
	display: none;
	&.on {
		display: block;
	}
	.popup {
		display: block;
		background: #fff;
		width: 800px;
		height: 500px;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -400px 0 0 -400px;
	}
}