/** Manage page */
@import "colors";
@import "mixins";

.manage {
  h4 {
    font-weight: bold;
    padding-left: 6px;
    border-bottom: 1px solid darken($grey-input, 10%);
    span {
      float: right;
      font-size: 14px;
      font-weight: normal;
    }
  }
  table {
    margin: 0 0 30px;
    tr.filtered {
      display: none;
    }
  }
  &.selection {
    &.apilist {
      tr {
        &:hover {
          cursor: auto;
        }
      }
 
    }
  }
  .user-type {
    width: 376px;
    float: left;
    margin: 0 20px 20px 0;
    @include box-sizing(border-box);
    @include border-radius(10px);
    padding: 15px;
    background: $grey-input;
    border: 4px solid darken($grey-input, 5%);
    &:nth-child(odd) {
      margin-right: 0;
    }
    h4 {
      background: none;
      border: none;
      margin: 6px 0 0;
      padding: 0;
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      color: darken($grey-input, 30%);
      text-transform: uppercase;
    }
    span {
      text-align: center;
      font-size: 40px;
      display: block;
    }
  }
  .role-search {
    background: $grey-input;
    border: 1px solid $grey-canon;
    padding: 4px;
    width: 250px;
    border-radius: 4px;
    margin-top: 8px;
    input {
      border: none;
      width: 100%;
      background: none;
    }
  }
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(2.25rem + 2px);
  padding: .375rem 1.75rem .375rem .75rem;
      padding-top: 0.375rem;
      padding-bottom: 0.375rem;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right .75rem center;
      background-size: auto;
  background-size: 8px 10px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/** Create Forms */
.create,
.edit {
  width: 96%;
  margin: 50px auto 20px;
  overflow: hidden;
  position: relative;
  background: lighten($grey-canon, 8%);
  border: 1px solid lighten($grey-canon, 2%);
  @include border-radius(10px);

  /* Tooltip text */

  .tooltip .tooltiptext {
    left: -5px;
  }

  .tooltip .tooltiptext::before {
    left: 5px;
  }

  &[data-module="expandable"] {
    height: 46px;
    .bi {
      width: 720px;
      height: 30px;
      position: absolute;
      top: 8px;
      right: 10px;
      cursor: pointer;
      &:before {
        float: right;
        margin-top: 8px;
      }
    }
    &.open {
      height: auto;
    }
  }

  .wrap {
    padding: 15px;
    overflow: hidden;
  }

  h3 {
    color: $red-canon;
    margin-bottom: 20px;
  }

  .form_element {
    margin: 10px 0;
    &.right {
      float: right;
    }
    &.left {
      float: left;
      margin-right: 10px;
    }
  }
  p {
    width: 75%;
    font-size: 13px;
    line-height: 19px;
    margin: 20px 0;
    color: $grey-dark;
  }
  .input_field {
    width: 230px;
    height: 32px;
    background: $grey-canon;
    &.apilist {
      width: 530px;
      input {
        width: 510px;
      }
    }
    input {
      background: none;
      height: 28px;
      line-height: 30px;
      width: 210px;
      margin: 1px 0 0 10px;
      font-size: 14px;
    }
    select {
      background: none;
      height: 28px;
      line-height: 30px;
      width: 220px;
      margin: 1px 0 0 4px;
      font-size: 13px;
      border: 0;
    }
  }
  label {
    color: darken($grey-canon, 30%);
    display: block;
    margin: 0 0 6px;
    font-weight: 300;
  }
  button,
  a.button {
    height: 32px;
    line-height: 28px;
    font-size: 15px;
    padding: 0 20px;
    margin: 25px 0 0 0;
    &.left {
      float: left;
      clear: left;
    }
    &.right {
      float: right;
      clear: right;
    }
    &:hover {
      cursor: pointer;
    }
  }

  .errors {
    margin: 0 0 30px;
    border: 1px solid $red-canon;
    padding: 15px;
    @include border-radius(10px);
    li {
      color: $red-canon;
      font-size: 13px;
      font-weight: normal;
      margin-bottom: 5px;
      list-style: inside disc;
      padding-left: 10px;
      &.title {
        color: $grey-dark;
        list-style: none;
        padding-left: 0;
        margin-bottom: 10px;
      }
    }
  }

  .form_divider {
    clear: both;
    font-size: 16px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: darken($grey-canon, 40%);
    border-bottom: darken($grey-canon, 5%) solid 1px;
    padding: 0 0 10px 4px;
  }
  .form_column {
    width: 240px;
    margin: 10px 0 20px;
    &.left {
      float: left;
    }
    &.right {
      float: right;
    }
  }
  .form_chunk {
    clear: both;
    float: left;
    margin: 10px 0 20px;
  }

  .engineer-access {
    margin: 15px 0 15px;

    li {
      color: $red-canon;
      font-weight: bold;
      font-size: 15px;
      > ul {
        margin: 10px 0 0 30px;
        li {
          margin: 5px 0 5px;
          font-size: 13px;
          color: $grey-dark;
          font-weight: normal;
        }
      }
    }
  }

  .note-new {
    width: 99%;
    max-width: 99%;
    height: 90px;
  }
  
  .note-old {
    width: 97%;
    word-break: break-word;
    height: auto;
    border-width: 0.5px;
    border-style: solid;
    border-color: #666;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10x !important;
    border-radius: 2px;
    box-shadow: 0px 0px 8px #aaa inset;
    font-size: 14px;;
  }

  &.notes {
    label {
      &.note-header {
        margin-top: 10px;
        font-size: 14px;
        span {
          color: black;
        }
      } 
    }
  }

  &.create-inside, &.edit-inside {
    width: 100%;
    margin: 10px auto 10px;
    overflow: hidden;
    position: relative;
    background: #f2f2f2;
    border: 1px solid #e3e3e3;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    label {
      &.note-header {
        margin-top: 10px;
        font-size: 14px;
        span {
          color: black;
        }
      } 
    }
    h3 {
      font-size: 14px;
    }
  }
}

.edit {
  width: 100%;
  border: none;
  background-color: #fff;
  margin-top: 0px;
  border-radius: 0;

  .input_field {
    width: 340px;
    input,
    select {
      width: 330px;
    }
  }

  .form_column {
    width: 360px;
  }

  a.button {
    @include border-radius(4px);
    &.secondary {
      height: 31px;
      background-color: #fff;
      color: #000;
      border: 1px solid #ce2229;
      &:hover {
        background-color: #b81e25;
        color: #fff;
      }
    }
  }

  .buttons {
    display: block;
    width: 100%;
    float: left;
    button.right,
    a.button.right {
      clear: none;
    }
    button,
    a.button {
      margin: 0px 5px;
    }
  }
}

p.success {
  color: $green-lfp;
  padding: 15px;
  border: 1px solid $green-lfp;
  @include border-radius(10px);
  margin: 20px 0;
}
