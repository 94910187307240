/* JSTree
**********************************************/
#homegroup_tree.jstree-classic li, #homegroup_tree .jstree a, #browse_tree.jstree-classic li, #browse_tree .jstree a {
	font-size:14px;
	font-family: 'Open Sans', sans-serif;
	color:#8D8D8D;
}
#homegroup_tree.jstree-classic .jstree-clicked, #browse_tree.jstree-classic .jstree-clicked { 
  background:#C80505; 
	border:1px solid #C80505; 
	padding:0 2px 0 1px; 
	color:#EEEEEE; 
	-webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#homegroup_tree.jstree-classic .jstree-clicked.jstree-hovered, #browse_tree.jstree-classic .jstree-clicked.jstree-hovered {
	background:#C80505; 
	border:1px solid #C80505; 
	padding:0 2px 0 1px; 
	color:#EEEEEE; 
	-webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
#homegroup_tree.jstree-classic.jstree-focused, #browse_tree.jstree-classic.jstree-focused {
	background:none;
}
#homegroup_tree.jstree-classic .jstree-hovered, #browse_tree.jstree-classic .jstree-hovered { 
  background:none; 
	border:1px solid #e7f4f9; 
	padding:0 2px 0 1px;
	color:#525252; 
}
#homegroup_tree, #browse_tree {
	min-height:250px;
}
#homegroup_tree .device a, #browse_tree .device a {
	font-family:Consolas,monospace;
}
#homegroup_tree .device a .jstree-icon, #homegroup_tree .devices a .jstree-icon, #browse_tree .device a .jstree-icon {
	background:url(../images/tree.device.png) no-repeat top center;
}
#homegroup_tree .device a.jstree-clicked .jstree-icon, #browse_tree .device a.jstree-clicked .jstree-icon {
	background-position:bottom;
}
#homegroup_tree .user a .jstree-icon, #browse_tree .user a .jstree-icon {
	background:url(../images/tree.user.png) no-repeat top center;
}
#homegroup_tree .user a.jstree-clicked .jstree-icon, #browse_tree .user a.jstree-clicked .jstree-icon {
	background-position:bottom;
}