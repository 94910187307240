@import "colors";
@import "mixins";

.select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
	height: auto;
	line-height: 14px;
	font-size: 15px;
	padding: 0 0px;
	margin: 0;
	border-right: 0 none;
}

.notifications {

	.counts {
		clear: both;
		overflow: hidden;
		margin: 20px 0;
	}
	.create {
		margin-top: 80px;
	}
	.count {
		width: 376px;
		float: left;
		margin: 0 20px 20px 0;
		@include box-sizing(border-box);
		@include border-radius(10px);
		padding: 15px;
		background: $grey-input;
		border: 4px solid darken($grey-input, 5%);
		&:nth-child(odd){
			margin-right: 0;
		}
		h4 {
			background: none;
			border: none;
			margin: 6px 0 0;
			padding: 0;
			font-size: 15px;
			font-weight: normal;
			text-align: center;
			color: darken($grey-input, 30%);
			text-transform: uppercase;
		}
		span {
			text-align: center;
			font-size: 40px;
			display: block;
		}
	}

	.create, .notification-filters {

		.term-input {
			input{ 
				padding: 5px;
			}
		}

		.select, .input{
			background: #dedede;
			width: 300px;
			padding: 0;
			border-radius: 2px;
			margin-bottom: 2px;
			float: left;
			clear: left;
			select, input {
				padding: 5px;
				width: 300px;
				background: transparent;
				border: none;
			}
			input {
				width: 290px;
				margin-left: 7px;
				&.input-create {
					width: 198px;
				}
			}
		}
	}

	.create.my-report {
		margin-top: 0 !important;
	}

	.create.reporting {

		.select, .input {
			background: transparent;
			width: 470px !important;
			padding: 0;
			border-radius: 2px;
			margin-bottom: 2px;
			float: left;
			clear: left;
			select, input {
				width: 470px;
				background: transparent;
				border: none;
				&.selreseller {
					width: 220px;
				}
			}
			&.reseller {
				margin-top: 33px;
			}
			input {
				width: 460px;
				margin-left: 7px;
			}
		}
	}

	.notification-filters {
		float: right;
		clear: both;

		.select {
			width: 150px;
			select {
				width: 150px;
			}
		}
	}

	.notification-option {
		padding: 5px 0;
		clear: both;
	}

	&.ondevice {
		.notification-type {
			float: left;
			width: 730px;
			margin-top: 40px;
			.option-ink, .notification-option {
				width: 300px;
				margin-right: 60px;
				&.right {
					margin-right: 0;
					float: right;
				}
				&.waste {
					 clear: left;
				}
				i.warnings {
					background-color: #D8DC42;
				}
				i.errors {
					background-color: #880000;
				}
			}
			&[data-type='errorsandwarnings'] {
				.select {
					float: left;
					clear: none;
					margin-left: 10px;
				}
			}
			.recipients {
				width: 600px;
				float: left;
				clear: left;
				margin-top: 20px;
				margin-bottom: 20px;
				.label {
					font-size: 12px;
				}
				.input {
					float: left;
					width: 300px;
					clear: none;
					margin-left: 10px;
					height: 18px;
					input {
						height: 17px;
					}

				}
			}
			.label {
				width: 150px;
			}
			.threshold {
				float: right;
				width: 140px;
				margin: 0;
				clear: none;
				select, input[type=number] {
					width: 138px;
					margin-left: 0;
				}
			}
			button, .back-button {
				background: $red-canon;
				border: none;
				color: #fff;
				float: right;
				padding: 8px 16px;
				margin-top: 20px;
				cursor: pointer;
				@include transition(background 0.25s ease-in-out);
				&:hover {
					background: darken($red-canon, 8%);
				}
			}

			.back-button {
				width: 60px;
				float: left;
				text-align: center;
				font-size: 14px;
				padding: 8px 16px;
				margin-top: 100px;
				@include border-radius(4px);
				background: $grey-canon;
				color: $grey-dark;
				&:hover {
					background: darken($grey-canon, 8%);
				}
			}

			.buttons {
				clear: both;
				float: left;
				margin-top: 100px;
				width: 100%;
				button, a {
					margin: 0;
				}
			}
		}
		.select, .input {
			background: #dedede;
			width: 140px;
			padding: 2px 0;
			border-radius: 2px;
			margin-bottom: 2px;
			float: left;
			clear: left;
			&.reports {
				width: 470px;
				select, input {
					width: 460px;
				}
			}
			&.devices{
				width: 470px; 
				margin-top: 0;
				select {
					width: 470px; 
				}
			}
			select, input {
				width: 140px;
				background: transparent;
				border: none;
			}
			input {
				width: 290px;
				margin-left: 7px;
			}
		}
	}

	.notification-type {
		display: none;
		float: right;
		width: 380px;
		&.enabled {
			display: block;
		}

		.option-ink {
			overflow: hidden;
			float: left;
			width: 380px;
			margin-bottom: 4px;
			&.waste {
				margin-top: 20px;
			}
		}
		button {
			float: right;
			border-radius: 4px;
		}
		.label {
			float: left;
			width: 158px;
			display: block;
			margin-top: 1px;
			i {
				display: block;
				float: left;
				margin-right: 10px;
				border-radius: 100%;
				margin-top: 3px;
				text-align: center;
				color: #fff;
				font-size: 14px;
				padding: 5px;
			}
			em {
				font-size: 12px;
				margin-top: 7px;
				display: block;
			}

		}
		.threshold {
			float: right;
			width: 214px;
			margin: 0;
			clear: none;
			select {
				width: 208px;
			}
		}
		.w-50 select, .notification-select select {
			width: 100%;
		}
	}

	.notification-status {
		display: block;
		margin: 0 0 10px;
		padding: 20px 20px 20px 20px;
		overflow: hidden;
		border-bottom: 1px solid #eee;
		position: relative;
		clear: both;
		.device {
			margin: 0 0 10px;
			display: block;
			overflow: hidden;
			padding-left: 4px;
			i {
				//width: 33.3%;
				//display: block;
				//float: left;
				font-size: 13px;
				margin-right: 10px;
				strong {
					font-weight: bold;
					font-family: 'Open Sans';
				}
			}
			.model {

			}
			.serial {

			}
			.location {

			}
		}
		.recips {
			clear: left;
			float: left;
			margin: 2px 0 4px 0;
			font-size: 11px;
			color: #666;
			padding-left: 4px;
			i {
				font-family: 'Open Sans';
			}
		}
		.dates {
			clear: left;
			float: left;
			margin: 8px 0 4px 0;
			font-size: 11px;
			color: #666;
			padding-left: 4px;
			i {
				padding-right: 8px;
				strong {
					font-family: 'Open Sans';
				}
			}
		}
		.edit {
			position: absolute;
			top: 20px;
			right: 0;
			border: none;
			//color: #fff;
			//background: $red-canon;
			color: #999;
			background: transparent;
			width: 25px;
			height: 25px;
			@include border-radius(100%);
			text-align: center;
			font-size: 13px;
			&:hover {
				color: #333;
			}
		}
		.delete {
			position: absolute;
			top: 20px;
			right: 20px;
			border: none;
			//color: #fff;
			//background: $red-canon;
			color: #999;
			background: transparent;
			width: 25px;
			height: 25px;
			@include border-radius(100%);
			text-align: center;
			font-size: 13px;
			&:hover {
				color: #333;
			}
		}
		.warningorerror {
			width: auto;
			float: left;
			margin-right: 20px;
			margin-top: 10px;
			&.type-errors {
				//880000
				&.frequency-live {
					i {
						background-color: #880000;
					}
				}
				&.frequency-daily {
					i {
						background-color: #880000;
					}
				}
				&.frequency-custom {
					i {
						background-color: #880000;
					}
				}
			}
			&.type-warnings {
				//888800
				&.frequency-live {
					i {
						background-color: #D8DC42;
					}
				}
				&.frequency-daily {
					i {
						background-color: #D8DC42;
					}
				}
				&.frequency-custom {
					i {
						background-color: #D8DC42;
					}
				}
			}
			em {
				line-height: 25px;
				font-size: 11px;
				margin-left: 10px;
				span {
					font-weight: bold;
				}
			}
			i {
				display: block;
				float: left;
				border-radius: 100%;
				text-align: center;
				color: #fff;
				padding: 5px;
			}
		}
		.communicationnotice {
			&.type-device-list{
				float: left !important;
				clear: left;
			} 
			&.type-notice-email  {
				float: left !important;
				clear: none !important;
			}

		}
		.inkandwaste, .communicationnotice {
			clear: left;
			.ink, .email-notice, .device-list-notice {
				width: 120px;
				float: left;
				margin-top: 10px;
				i {
					display: block;
					float: left;
					border-radius: 100%;
					text-align: center;
					color: #fff;
					padding: 5px;
				}
			}
			em {
				line-height: 25px;
				font-size: 12px;
				margin-left: 10px;
				i {
					font-weight: bold;
				}
			}
		}
	}
}


p.success {
	color: $green-lfp;
	padding: 15px;
	border: 1px solid $green-lfp;
	@include border-radius(10px);
	margin: 20px 0;
}

ul.errors {
	margin: 0 0 30px;
	border: 1px solid $red-canon;
	padding: 15px;
	@include border-radius(10px);
	li {
		color: $red-canon;
		font-size: 13px;
		font-weight: normal;
		margin-bottom: 5px;
		list-style: inside disc;
		padding-left: 10px;
		&.title {
			color: $grey-dark;
			list-style: none;
			padding-left: 0;
			margin-bottom: 10px;
		}
	}
}

.reporting .select select {
	width: 450px;
	background: transparent;
	border: none;
}

.reporting .select .input_field.airbus {
	padding: 10px;
	background: #f1f1f1;
}

.reporting .select {
	background: transparent;
	width: 470px !important;
	padding: 0;
	border-radius: 2px;
	margin-bottom: 2px;
	float: left;
	clear: left;
}

.input_field.round.report.checkbox {
	text-align: center;
}

.input.communication-notes-recipients {
	width: 100% !important;
}

.communication-notice-section {
	margin-bottom: 40px;
}

.communication-notice {
	margin: 0 0 10px 0 !important;
}

.w-50 {
	width: 55% !important;
	float: left !important;
	clear: unset !important;
	&.term {
		margin-right: 2%;
		padding: 3px !important;
		width: 35% !important;
		&.edit-notification {
			padding: 8px 3px !important;
		}
		&.create-notification {
			padding: 6px 3px !important;
		}
	}
	.term_period {
		width: 100% !important;
	}
}

.notification-edit.button {
	float: left;
	clear: both;
	padding: 8px 16px;
	border-radius: 4px;
}

.form_chunk.communication-notice-edit .theme-button {
	float: none !important;
	padding: 11px 16px;
	border-radius: 4px;
	height: auto !important;
	line-height: 23px;
}

.notification-option.errors-custom,
.notification-option.warnings-custom {
	.input {
		margin-bottom: 10px;
	}
}
